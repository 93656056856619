
const en = {
    
    requested_materials :"REQUESTED MATERIALS",
    req_dates:"Requested Dates",
    total_qty_warehouse:"Tot. Qty",
    jobs_warehouse:"Jobs",
    material_notes:"Material Notes",
    created_jobs_in_warehouses:"Created jobs",
    create_job_warehouse:"Create job",
    save_jobs_warehouse:"Are you sure you want to save the jobs?",
    toaster_job_successful_warehouse:"Job created successfully",
    material_save_toaster_warehouse:"Material  {mat}  was saved successfully!",

    // Create job modal

    job_type:"Job type",
    warehouse: "warehouse",
    venue: "venue",
    rental: "rental",
    select_warehouse: "Select warehouse", 
    stockout: "Stockout",
    quantity_info: "Quantity info",
    dates: "Dates",
    warehouse_quantity: "Warehouse quantity",
    job_quantity: "Job quantity",
    remaining_quantity: "Remaining quantity",
    
    material_amount_updated_to: "{mat} amount was updated to {qty}",
    material_removed_from_job: "{mat} was removed from job",
    job_deleted_successfully: 'Job deleted successfully',
    select_date_warehouse: 'Select date',
    operational_pdf: 'Operational Pdf',
    executive_pdf: 'Executive Pdf',
    pickup_date: 'Pickup date',
    amount: 'Amount',
    confirm_dates: 'Confirm dates',
    number: 'number',
    quantity: 'quantity',
    status: 'status',
    qty_not_higher: 'The quantity can not be higher than {qty}',
    are_you_sure_remove_material: 'Are you sure you want to remove {mat}?',
    cmaterial_removed_success: 'Material removed successfully',

    // New translations
    job_can_not_be_saved: 'There are {has_invalid_dates} jobs that cannot be saved!',
    there_is_a_problem_with_dates: 'There is a problem with {number} of the dates.',
    save_jobs: 'Save jobs',

    note_created_successfully: 'Material note created successfully',
    material_not_found: 'Material not found',

    bulk_assign: 'Bulk assign',
    please_select_dates_below: 'Please select one of the dates below',
    added_pending_job_list: 'Added to pending job list.'
};

export default en;