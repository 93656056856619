const en = {
    
    roles: "Roles",
    users: 'Users',
    select_role: 'Select role',
    create_new_role: 'Create new role',
    create_role_btn: 'Create role',
    role_name: 'Role name',
    assign_role_to_user: 'Assign role to user',
    uncheck_all: 'Uncheck all',
    check_all: 'Check all',
    save_changes: 'Save changes',
    please_select_role: 'Please select role',
    permission_saved_successfuly: 'Permissions have been saved',
    permission_saved_successfuly_toast: 'Role {role_name} has been saved',
    save_changes_permissions: 'Save changes',
    role_assigned_to_user: 'Role {role_name} has been assigned to user {user_name}',
    create_new_user: 'Create new user',
    create_user_btn: 'Create user',
    system_admin: 'System admin',
    no_role_assigned: 'No role assigned',
    
    // Modules names
    request_module: 'Request',
    warehouse_module : 'Warehouse',
    parameter_module : 'Parameter',
    planning_module : 'Planning',
    help_module : 'Help',
    user_roles_module: 'User roles',
    role_permissions_module: 'Permissions',

    //Request permissions, descriptions
    request_can_read: 'Can read',
    description_request_can_read: 'Can see the request page',
    request_can_edit: 'Can edit',
    description_request_can_edit: 'Can edit the request dates and amount',
    request_can_delete: 'Can delete',
    description_request_can_delete: 'Can delete',
    request_can_finalize_request: 'Can finalize request',
    description_request_can_finalize_request: 'Can see finalize request button',

    //Warehouse permissions, descriptions
    warehouse_can_read: 'Can read',
    description_warehouse_can_read: 'Have access to warehouse page.',
    warehouse_can_edit: 'Can edit',
    description_warehouse_can_edit: 'Can edit warehouse job',
    warehouse_can_delete: 'Can delete',
    description_warehouse_can_delete: 'Can delete warehouse job',
    warehouse_can_create_job: 'Can create job.',
    description_warehouse_can_create_job: 'Can create warehouse job.',
    warehouse_can_confirm_job: 'Can confirm job',
    description_warehouse_can_confirm_job: 'Can confirm warehouse job',

    //Parameter permissions, descriptions
    parameter_can_read:'Can read',
    description_parameter_can_read: 'Have access to parameter page.',
    parameter_can_create:'Can create',
    description_parameter_can_create: 'Can create parameters',
    parameter_can_edit:'Can edit',
    description_parameter_can_edit: 'Can edit parameters',
    parameter_can_delete:'Can delete',
    description_parameter_can_delete: 'Can delete parameters',

    planning_can_read:'Can read',
    description_planning_can_read: 'Have access to planning page.',

    help_can_create: 'Can create help',
    help_can_create_description: 'Can create help description',
    help_can_edit: 'Can edit help',
    help_can_edit_description: 'Can edit help description',
    help_can_delete_ohers: 'Can delete others hepls',
    help_can_delete_ohers_description: 'Can delete others hepls description.',

    user_roles_can_create: 'Can create users',
    user_roles_can_create_description: 'Can create users description',
    user_roles_can_edit: 'Can edit users',
    user_roles_can_edit_description: 'Can edit users description',
    user_roles_can_disable: 'Can disable users',
    user_roles_can_disable_description: 'Can disable users description',

    role_permissions_can_create: 'Can create role',
    role_permissions_can_create_description: 'Can add a new user to the system',
    role_permissions_can_edit: 'Can edit role',
    role_permissions_can_edit_description: 'Can change the name of the user',
    role_permissions_can_assign: 'Can edit permissions',
    role_permissions_can_assign_description: 'Can edit the permissions assigned to a role',


};

export default en;