
const en = {
    welcome: 'Welcome {name}',
    log_out: 'Logout',
    select_project: 'Select project',
   
    materials_amount: 'Materials amount',
    materials_amount_to_accept: 'Materials to accept',
    parameters: 'General information',
    planning: 'Planning',
    loading: 'Loading',
    routes: 'Routes',


};

export default en;