import Vue from 'vue'
import App from './App.vue'
require('@/assets/main.scss');
import router from './routes.js'
import moment from 'moment'

import 'moment/locale/de'
import 'moment/locale/it'

var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo)

require('vue2-animate/dist/vue2-animate.min.css')

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VAnimateCss from 'v-animate-css';
import Toasted from 'vue-toasted';
import VueSimpleAlert from "vue-simple-alert";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'vue-search-select/dist/VueSearchSelect.css'

// Tooltip 
import VueTippy, { TippyComponent } from "vue-tippy";
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);



// Language
import i18n from "./language/i18n"; // RAR file
i18n.locale = localStorage.lang ? localStorage.lang : "en";
moment.locale(i18n.locale)

DatePicker.locale('de');
Vue.use(DatePicker);
Vue.use(flatPickr);
Vue.use(VAnimateCss);
Vue.use(Toasted, {
  iconPack: 'fontawesome'
});
// import VueSocketIOExt from 'vue-socket.io-extended';
// import { io } from 'socket.io-client';

// const socket = io('http://localhost:3003');

// Vue.use(VueSocketIOExt, socket);



// Confirm dialog
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
// import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'; 
// only needed in custom components

// Tell Vue to install the plugin.
Vue.use(VuejsDialog);

Vue.prototype.$document = document
Vue.prototype.$moment = moment

Vue.use(require('vue-moment'));
Vue.use(VueSimpleAlert);

Vue.filter('uppercase', function(value) {
  return value.toUpperCase()
})
Vue.filter('datetime', function(value) {
  return value ? moment(String(value)).format('DD.MM.YYYY HH:mm') : ''
})

Vue.filter('time', function(value) {
  return value ? moment(String(value)).format('HH:mm') : ''
})
Vue.filter('local-datetime', function(value) {
  let user = JSON.parse(localStorage.tetris_user)
  return value ? moment(String(value)).add(user.timezone_offset, 'hours').fromNow() : '' //.format('DD.MM.YYYY HH:mm') : ''
})
Vue.filter('customdate', function(value) {
  moment.locale(i18n.locale)
  return value ? moment(String(value)).format('ddd DD.MM.YYYY') : ''
})

Vue.filter('customdate2', function(value) {
  moment.locale(i18n.locale)
  return value ? moment(String(value)).format('ddd DD.MM.YYYY') : ''
})

Vue.filter('systemdate', function(value) {
  return value ? moment(String(value)).format('YYYY-MM-DD') : ''
})


Vue.filter('rounded', function(value) {
  return value ? Math.round(value * 100) / 100 : 0
})


Vue.filter('number', function(value) {
  return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(value)
})

var filter = function(text, length, clamp) {
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.filter('truncate', filter);


Vue.config.productionTip = false


// PROTOTYPES
// Tetris server
Vue.prototype.$tetris_server = process.env.NODE_ENV == 'development'
  ? window.location.origin.replace('8080', '8000') + "/api/v1" // "http://192.168.16.105:8000/api/v1"
  : "https://backend.tetris.eps.net/api/v1"

// EASY JOB server url (TESTING SERVER: 8009 - LIVE SERVER: 8008)
Vue.prototype.$ej_server = "https://eps.mio-cloud.com:8009"
Vue.prototype.$EventBus = new Vue(); // Global event bus
// easy job headers
Vue.prototype.$ej_headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json; charset=UTF-8',
  'Authorization': 'Bearer ' + localStorage.getItem('access_token')
}

Vue.prototype.$toast = function(message, type, duration) {
  this.$toasted.show(message, {
    position: 'bottom-right',
    duration: duration ? duration : 3000,
    keepOnHover: true,
    type: type ? type : 'info',
    className: type == 'info' || !type ? ['toasted-info'] : [],
    // theme: 'outline',
    html: true,
    icon: {
      name: 'info',
    },
    action: []
  })
}

export const EventBus = new Vue({})

new Vue({
  router,
  render: h => h(App),
  i18n,
}).$mount('#app')
