const de = {
    welcome: 'Willkommen {name}',
    save: 'Speichern',
    yes: 'Ja',
    no: 'Nein',
    delete: 'Löschen',
    are_you_sure: 'Bist Du sicher?',
    search: 'Suchen',
    search_dots: 'Suche...',
    name_general: 'Name',
    selected_general: 'Ausgewählt',
    missing_value_general: 'EINGABE FEHLT',
    
    // Comments
    project_comments: 'Kommentar',
    guest_comments: 'Gast',
    creating_message: 'Kommentar speichern.',
    sure_delete_comment: 'Möchtest Du den Kommentar löschen?',
    comment_deleting: 'Kommentar gelöscht',
    note_deleting: 'Note is deleting',
    sure_delete_note: 'Are you sure you want to delete this note?',
 

    // Help
    help: 'Help',
    create_help: 'Create new help',
    help_title: 'Title',
    help_content: 'Help content',
    save_sort: 'Save sort',
    help_created_successfully: 'Help created successfully',
    help_edited_successfully: 'Help saved',
    are_you_sure_delete_help: 'Are you sure you want to delete this help?',
    help_deleted_successfully: 'Help deleted',
    sort_updated_successfully: 'Sort updated',

    //New translations
    is_creating_message: 'Saving comment ...'
};

export default de;