import axios from 'axios'

export default(sendToken = false) => {
    try {
        return axios.create({
            baseURL: "https://eps.mio-cloud.com:8009", 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Authorization': sendToken ? `Bearer ${JSON.parse(localStorage.getItem('access_token'))}` : 'basic',
        }, 
        // withCredentials: true
    })
    } catch(err) {
        // unset token here
    }
}


