import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
    {path: '/', component: () => import('./components/project.vue')},

    {path: '/delivery-request/:id', component: () => import('./components/steps/GeneralRequest.vue')},
    {path: '/request-accept/:id', component: () => import('./components/steps/warehouse-accepting/index.vue')},
    {path: '/delivery-parameters/:id', component: () => import('./components/steps/Parameters.vue')},
    {path: '/delivery-plan/:id', component: () => import('./components/steps/planning/DeliveryPlan.vue')},
    {path: '/delivery-routes/:id', component: () => import('./components/steps/WareWorld.vue')},
    {path: '/user-roles', component: () => import('./components/user-roles.vue')},
    {path: '/loading-module/:id', component: () => import('./components/steps/loading/loading-module.vue')}
];

export default new VueRouter({ routes,  linkActiveClass: 'is-active'})

