const de = {
    welcome: 'Willkommen {name}',
    log_out: 'Logout',
    select_project: 'Projekt auswählen',

    materials_amount: 'Anzahl',
    materials_amount_to_accept: 'Materialien freizugeben',
    parameters: 'Transportparameter',
    planning: 'Transportplanung',
    loading: 'Loading',
    routes: 'Routes',
};

export default de;
