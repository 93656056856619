const de = {
    
    roles: "Roles",
    users: 'Users',
    select_role: 'Select role',
    create_new_role: 'Create new role',
    create_role_btn: 'Create role',
    role_name: 'Role name',
    assign_role_to_user: 'Assign role to user',
    uncheck_all: 'Uncheck all',
    check_all: 'Check all',
    save_changes: 'Save changes',
    please_select_role: 'Please select role',
    permission_saved_successfuly: 'Permissions have been saved',
    permission_saved_successfuly_toast: 'Role {role_name} has been saved',
    save_changes_permissions: 'Save changes',
    role_assigned_to_user: 'Role {role_name} has been assigned to user {user_name}',
    create_new_user: 'Create new user',
    create_user_btn: 'Create user',
    
    // Modules names
    request_module: 'Request',
    warehouse_module : 'Warehouse',
    help_module : 'Help',
    user_roles_module: 'User roles',

    request_can_read: 'Can read',
    description_request_can_read: 'Can see the request page.',
    request_can_delete: 'Can delete',
    description_request_can_delete: 'Can delete in the request page.',

    warehouse_can_create_job: 'Can create job.',
    description_warehouse_can_create_job: 'Can create job in the warehouse page.',

    help_can_create: 'Can create help',
    help_can_create_description: 'Can create help description',
    help_can_edit: 'Can edit help',
    help_can_edit_description: 'Can edit help description',
    help_can_delete_ohers: 'Can delete others hepls',
    help_can_delete_ohers_description: 'Can delete others hepls description.',

    user_roles_can_create: 'Can create users',
    user_roles_can_create_description: 'Can create users description',
    user_roles_can_edit: 'Can edit users',
    user_roles_can_edit_description: 'Can edit users description',
    user_roles_can_disable: 'Can disable users',
    user_roles_can_disable_description: 'Can disable users description',

    user_roles_can_create_role: 'Can create role',
    user_roles_can_create_role_description: 'Can add a new user to the system',
    user_roles_can_edit_role: 'Can edit role',
    user_roles_can_edit_role_description: 'Can change the name of the user',
    user_roles_can_edit_permissions: 'Can edit permissions',
    user_roles_can_edit_permissions_description: 'Can edit the permissions assigned to a role',


};

export default de;