
const en = {
    request:"REQUEST",
    group:"Group",
    material:"Material",
    amount:"Amount",
    from_until:"From-Until",
    notes:"Notes",
    accepted:"Accepted",
    requested:"Requested",
    add_date:"add a date",
    finish_request:"Finish Request",
    delete_material:'Are you sure you want to delete this material?',
    material_save_toaster:"Material  {mat}  was saved successfully!",
    notes_title:"Notes",
    create_note:"Create",
    note_placeholder:"Create note",
    add_note:"Add note",
    toaster_note_created:"Note created",
    toaster_note_saved:"Note saved successfully.",
    toaster_note_deleted:"Note deleted",

    // New translations
    request_dates_finalized: 'Request dates finalized',
    has_been_added_by: 'has been added by',
    has_been_editet_tetris_ej: ' has been edited in Tetris and EasyJob by ',
    has_been_editet_tetris: ' has been edited in Tetris by ',
    has_been_changed_in_tetris: ' has changed in Tetris by ',
    please_reload_page_request: 'Please reload the page in order to see the changes!',
    new_note_added_by: 'A new note has been added by',
    note_deleted_by: 'A note has been deleted by',
    note_edited_by: 'A note has been edited by', 
    confirm_increase_material_amount_ej: 'This operation will increase the amount of the material also in Easyjob',
    mat_amount_increased_by_one: 'This materials amount was increased by one!',
    
    





};

export default en;
