const de = {
    warehouses: 'Lagerstandort',
    supervisor: 'Supervisor',
    trucks: 'Lkw',
    truck_name: 'Lkw-Name',
    create_trucks: 'Neuen Lkw anlegen',
    delivery_locations: 'Lieferadressen',
    and_delivery_locations: 'Ansprechpartner vor Ort', 
    add_new_field: 'Neues Feld hinzufügen',
    text_param_modal: 'Text',
    with_options: 'Weitere Optionen',
    field_name: 'Feldname',
    required: '(required)',
    add_truck_name: 'Add truck name',
    add_supervisor: 'Ansprechpartner hinzufüge',
    main_address: 'Hauptlieferadresse',
    truck_name_saved: 'Truck name saved.',
    error_comunication: 'Error in communication',
    field_name_is_empty: 'Field name is epmty',
    field_type_is_empty: 'Field type is empty',
    date_field: 'Date',
    image_field: 'Image',
    pdf_field: 'PDF',

    general_information: 'General information',
    version_nr: 'Version Nr',
    Easyjob_project_number: 'Easyjob project number',
    show_dates: 'Show dates',
    project_manager: 'Project manager',
    delivery_adress: 'Delivery address',
    contact_on_site: 'Contact on site',
    comments: 'Comments',
    name: 'Name', 
    street_name: 'Street name',
    country: 'Country',
    mobile: 'Mobile',
    add_location: 'Add location',
    delete_supervisor: 'Delete supervisor',
    delete_truck: 'Delete truck',
    delete_location: 'Delete location',
    add_contact: 'Add contact person',
    warehouse: 'Warehouse',
    truck_type: 'Type',
    contact: 'Contact',
    address: 'Address',
    overview: 'Truck overview',
    time: 'Time',
    delivery_trucks: 'Delivery trucks',
    returning_trucks: 'Returning trucks',
    truck_planned: 'Truck planned',
    truck_loading: 'Truck loaded'
};

export default de;