const en = {
    welcome: 'Welcome {name}',
    save: 'Save',
    yes: 'Yes',
    no: 'No',
    delete: 'Delete',
    are_you_sure: 'Are you sure?',
    search: 'Search',
    search_dots: 'Search...',
    name_general: 'Name',
    selected_general: 'Selected',
    missing_value_general: 'MISSING VALUE',
    
    // Comments
    project_comments: 'Project comments',
    guest_comments: 'Guest',
    creating_message: 'Saving comment.',
    sure_delete_comment: 'Are you sure you want to delete this comment?',
    comment_deleting: 'Comment is deleting',
    note_deleting: 'Note is deleting',
    sure_delete_note: 'Are you sure you want to delete this note?',

    // Help
    help: 'Help',
    create_help: 'Create new help',
    help_title: 'Title',
    help_content: 'Help content',
    save_sort: 'Save sort',
    help_created_successfully: 'Help created successfully',
    help_edited_successfully: 'Help saved',
    are_you_sure_delete_help: 'Are you sure you want to delete this help?',
    help_deleted_successfully: 'Help deleted',
    sort_updated_successfully: 'Sort updated',

    // New translations
    is_creating_message: 'Saving comment ...'


};

export default en;