const de = {
    
    delivery: 'Anlieferung',
    return: 'Rücklieferung',
    warehouse_materials: 'Gesamtmaterial je Lager',
    priject_materials: 'Gesamtmaterial des Projekt',
    materials_of: 'Material von',
    all_materials_of_project: 'Gesamtmaterial',
    material_planning: 'Material',
    job_number: 'Job number',
    dates_planning: 'Daten',
    quantity_planning: 'Menge',
    total_materials: 'Gesamtanzahl Materialien',
    jobs: 'Jobs',
    trucks: 'Truck',
    please_select_warehouse: 'Bitte wähle ein Lager',
    jobs_for: 'jobs von: ',
    collapse_all: 'Alle minimieren', 
    all_materials_loaded: 'Gesamtmenge geladen',
    total_planning: 'Gesamt',
    planned_planning: 'Geplant',
    loaded_planning: 'Geladen',
    pending_planning: 'Offen',
    returned_planning: 'Zurück',
    deliveries_planning: 'Anlieferungen',
    create_truck_to_load_materials: 'Bitte erstelle einen Truck um das Material zu planen...',
    hide_completed: 'Abgeschlossene verstecken',
    select_truck_planning: 'Wähle einen Truck', 
    departure_planning: 'Ladezeit',
    arrival_planning: 'Ankunft',
    location_planning: 'Adresse',
    select_location_planning: 'Adresse auswählen',
    weight_planning: 'Gewicht',
    dimentions_planning: 'Volumen',
    volume_planning: 'Volumen',
    load_planning: 'Menge',
    drag_drop_material_from_above: 'Material per Drag&Drop hinzufügen',
    add_material_to_truck: 'Material zum Truck hinzufügen.',
    specify_loaded_amount: 'Bitte gib die geladene Menge an, um das Laden abzuschließen',
    stops_planning: 'weitere Entladestellen',
    select_delivery_location: 'Bitte wähle die Lieferadresse aus',
    loading_finalized: 'Laden abgeschlossen.',
    truck_created_planning: 'Lkw erstellt.',
    sure_you_want_complete_loading_truck: 'Möchtest Du das Laden wirklich abschließen?',
    job_planning_completed: 'Planung der Lkw abgeschlossen',
    job_loading_completed: 'Beladung der Lkw abgeschlossen',
    specify_job_details: 'Transportinformationen einfügen!',
    sure_remove_material: 'Möchtest Du dieses Material wirklich entfernen?',
    material_removed_success: 'Material erfolgreich entfernt',
    sure_delete_truck_stop: 'Möchtest Du diese Entladestelle wirklich entfernen??', 
    sure_delete_truck_job: 'Möchtest Du dieses Lkw wirklich löschen?',
    truck_job_deleted_success: 'Lkw erfolgreich gelöscht',
    
        // Truck-job-modal
    amount_tj_modal: 'Menge', 
    left_tj_modal: 'noch zu planen',
    material_added_to_truck: 'Material hinzugefügt.',


    main_address: 'Main address' ,
    create_new_loading_truck: 'Create new truck loading job...',
    add_tj_stop: 'Add stop',
    are_you_sure_to_create_truck: 'Are you sure you want to create a new truck?',
    are_you_sure_to_add_stop: 'Are you sure you want to add a stop?',
    nr_materials: '{nr} materials',
    departure_date: 'Departure date',
    checkout: 'Checkout',
    checkin: 'Check in',
    add_material_ckeckin: 'Add material',
    dimentions: 'Dimentions',
    sure_you_add_new_mat_checkin: 'Are you sure you want to add  {mat}',
    sure_you_want_confirm_checkin: 'Are you sure you want to confirm checkin?<br><p class="has-text-danger">This operation will also checkin the materials at the EasyJob Application!</p>',
    counted_amount_saved: 'Amount saved',
    material_added_success_checkin: 'Material added successfully',
    specify_amount_checkin: 'Please specify the amount',
    materials_checkin_success: 'Materials checked in successfully',
    remove_new_material_confirm: 'Are you sure you want to remove {m}?',
    material_removed_successfully: 'Material removed successfully',
    job_info_checkin: 'Job info',
    departure_checkin: 'Departure',
    arrival_checkin: 'Arrival',

    number_checkin: 'Number',
    material_checkin: 'Material',
    unit_checkin: 'Unit',
    width_checkin: 'Width',
    height_checkin: 'Height',
    volume_checkin: 'Volume',
    depth_checkin: 'Depth',
    weight_checkin: 'Weight',
    amount_checkin: 'Amount',
    loaded_amount: 'Loaded amount',
    left_amount: 'Left',
    counted_amount: 'Counted',
    confirm_checkin: 'Confirm checkin',
    check_in_on: 'Check in on',
    add_comment: 'Add comment',
    create_comment: 'Create comment',
    delete_this_comment: 'Are you sure you want to delete this comment?',
    materials: 'Materials',
};

export default de;
