
const en = {
    welcome: 'Welcome {name}',
    panel_block: 'Panel block',
    unselected: 'Unselected',
    search:"Search",
    search_results:"Search results",
    changed_projects:"Changed projects",
    project_number:"Project number",
    project_name:"Project name",
    start_date:"Start Date",
    end_date:"End Date",
    newsfeed:"Newsfeed",
    priority:"Priority",
    type_change:"Type of change",
    type_todo:"Type/Todo",
    truck_events:"Truck Events",
    loading_today:"Loading Today",
    truckname:"Truck name",
    returned_trucks:"Returned trucks",
    load_more:"Load More",
    returning_today:"Returning today",
    loading_schedule:"Loading Schedule",
    chat_message: 'Chat message',
    release_message_1: 'Request Finalized',
    release_message_0: 'Not confirmed',

    // New translations
    delivered: 'Delivered',
    loading_return: 'Loading return',

    just_note: 'Warehouse job note',
    material_amount_changed_ititle: 'Material amount changed',
    material_amount_change_request_ititle: 'Material amount change',
    material_deleted_ititle: 'Material deleted',
    material_deleted_success_ititle: 'Material deleted',
    material_exchanged_ititle: 'Material exchanged',
    material_exchange_request_ititle: 'Material exchange request',
    note_type_0: 'Note - Normal proprity',
    note_type_1: 'Note - Medium proprity',
    note_type_2: 'Note - High proprity',
    nf_chat_message_0: 'Chat message - Normal proprity',
    nf_chat_message_1: 'Chat message - Medium proprity',
    nf_chat_message_2: 'Chat message - High proprity',
    
    nf_release_message_0: 'Chat message - Normal proprity',
    nf_release_message_1: 'Chat message - Medium proprity',
    nf_release_message_2: 'Chat message - High proprity',

};

export default en;
