const de = {
    welcome: 'Willkommen {name}',
    panel_block: 'Panel block',
    unselected: 'nicht ausgewählt',
    search:"Suche",
    search_results:"Suchergebnisse",
    changed_projects:"Geänderte Projekte",
    project_number:"Projekt Nummer",
    project_name:"Projekt Name",
    start_date:"Startdatum",
    end_date:"Enddatum",
    newsfeed:"Newsfeed",
    priority:"Priorität",
    type_change:"Art der Änderung",
    type_todo:"Typ/Todo",
    truck_events:"Anstehende Verladungen",
    loading_today:"heutige Verladungen",
    truckname:"Lkw-Name",
    returned_trucks:"Zurückgelieferte Lkw",
    load_more:"Lade-Modus",
    returning_today:"Heutige Rücklieferungen",
    loading_schedule:"Lieferschein/Kommiliste",
    release_message_1: 'Request Finalized',
    release_message_0: 'Request Pending',

    // New translations
    delivered: 'Delivered',
    loading_return: 'Loading return',

    just_note: 'Warehouse job note',
    material_amount_changed_ititle: 'Material amount changed',
    material_amount_change_request_ititle: 'Material amount change',
    material_deleted_ititle: 'Material deleted',
    material_deleted_success_ititle: 'Material deleted',
    material_exchanged_ititle: 'Material exchanged',
    material_exchange_request_ititle: 'Material exchange request',
    note_type_0: 'Note - Normal proprity',
    note_type_1: 'Note - Medium proprity',
    note_type_2: 'Note - High proprity',
    nf_chat_message_0: 'Chat message - Normal proprity',
    nf_chat_message_1: 'Chat message - Medium proprity',
    nf_chat_message_2: 'Chat message - High proprity',
    
    nf_release_message_0: 'Chat message - Normal proprity',
    nf_release_message_1: 'Chat message - Medium proprity',
    nf_release_message_2: 'Chat message - High proprity',

};

export default de;
