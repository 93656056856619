
const en = {
    
    delivery: 'Delivery',
    return: 'Return',
    warehouse_materials: 'Warehouse materials',
    priject_materials: 'Project materials',
    onsite_materials: 'Onsite materials',
    materials_of: 'Materials of',
    all_materials_of_project: 'All project materials',
    material_planning: 'Material',
    job_number: 'Job number',
    dates_planning: 'Dates',
    quantity_planning: 'Quantity',
    total_materials: 'Total materials',
    jobs: 'Jobs',
    trucks: 'Trucks',
    truck: 'Truck',
    please_select_warehouse: 'Please select location',
    jobs_for: 'jobs for: ',
    collapse_all: 'Collapse all ', 
    all_materials_loaded: 'All materials loaded',
    total_planning: 'Total',
    planned_planning: 'Planned',
    loaded_planning: 'Loaded',
    pending_planning: 'Planned',
    returned_planning: 'Returned',
    deliveries_planning: 'DELIVERIES',
    create_truck_to_load_materials: 'Create a Truck to load the materials...',
    hide_completed: 'Hide completed',
    select_truck_planning: 'Select truck', 
    departure_planning: 'Departure',
    arrival_planning: 'Arrival',
    location_planning: 'Location',
    select_location_planning: 'Select location',
    weight_planning: 'Weight',
    dimentions_planning: 'Dimension',
    volume_planning: 'Volume',
    load_planning: 'Quantity',
    drag_drop_material_from_above: 'Drag and drop materials from the list',
    add_material_to_truck: 'Add materials to truck.',
    specify_loaded_amount: 'Please specify the loaded amounts in order to finalize the loading',
    stops_planning: 'Stops',
    select_delivery_location: 'Select delivery location',
    loading_finalized: 'Loading finalized',
    truck_departed : 'A truck has departed',
    was_departed: 'The truck has departed',
    truck_created_planning: 'Truck created.',
    sure_you_want_complete_planning_truck: 'Are you sure you want to complete the planning of this truck?',
    sure_you_want_complete_loading_truck: 'Are you sure you want to complete the loading of this truck?<br><p class="has-text-danger">This operation will also checkout the materials at the EasyJob Application!</p>',
	
    sure_you_want_complete_loading_truck_return: 'Are you sure you want to complete the loading of this truck?',
    job_planning_completed: 'Job planning completed',
    job_loading_completed: 'Job loading completed',
    specify_job_details: 'Specify the job details!',
    sure_remove_material: 'Are you sure you want to remove this material?',
    material_removed_success: 'Material removed successfully',
    sure_delete_truck_stop: 'Are you sure you want to delete this truck stop?', 
    sure_delete_truck_job: 'Are you sure you want to delete this truck job?',
    truck_job_deleted_success: 'Truck job deleted successfully',

    // Truck-job-modal
    amount_tj_modal: 'Amount', 
    left_tj_modal: 'not loaded',
    material_added_to_truck: 'Material added to truck.',
    main_address: 'Main address' ,
    create_new_loading_truck: 'Create new truck loading job...',
    add_tj_stop: 'Add stop',
    are_you_sure_to_create_truck: 'Are you sure you want to create a new truck?',
    are_you_sure_to_add_stop: 'Are you sure you want to add a stop?',
    nr_materials: '{nr} materials',
    departure_date: 'Departure date',
    checkout: 'Checked out from',
    checkin: 'Check in',
    add_material_ckeckin: 'Add material',
    dimentions: 'Dimentions',
    sure_you_add_new_mat_checkin: 'Are you sure you want to add {mat}',
    sure_you_want_confirm_checkin: 'Are you sure you want to confirm checkin?<br><p class="has-text-danger">This operation will also checkin the materials at the EasyJob Application!</p>',
    counted_amount_saved: 'Amount saved',
    material_added_success_checkin: 'Material added successfully',
    specify_amount_checkin: 'Please specify the amount',
    materials_checkin_success: 'Materials checked in successfully',
    remove_new_material_confirm: 'Are you sure you want to remove {m}?',
    material_removed_successfully: 'Material removed successfully',
    job_info_checkin: 'Job info',
    departure_checkin: 'Departure',
    arrival_checkin: 'Arrival',

    number_checkin: 'Number',
    material_checkin: 'Material',
    unit_checkin: 'Unit',
    width_checkin: 'Width',
    height_checkin: 'Height',
    volume_checkin: 'Volume',
    depth_checkin: 'Depth',
    weight_checkin: 'Weight',
    amount_checkin: 'Amount',
    loaded_amount: 'Loaded amount',
    left_amount: 'Left',
    counted_amount: 'Counted',
    confirm_checkin: 'Confirm checkin',
    check_in_on: 'Check in on',
    add_comment: 'Add comment',
    create_comment: 'Create comment',
    delete_this_comment: 'Are you sure you want to delete this comment?',
    materials: 'Materials',
};

export default en;
