const de = {
    request:"Verfügbarkeitsanfrage",
    group:"Gruppe",
    material:"Material",
    amount:"Menge",
    from_until:"Von",
    notes:"Bermerkungen",
    accepted:"Freigegeben",
    requested:"Angefragt",
    add_date:"Zeitraum einfügen",
    finish_request:"Materialanfrage abschließen",
    delete_material:'Möchtest Du das Material wirklich löschen?',
    material_save_toaster:",Material {mat} wurde erfolgreich gespeichert!",
    notes_title:"Notes for: 'Name'",
    create_note:"Einfügen",
    note_placeholder:"Kommentar einfügen",
    add_note:"Notiz einfügen",
    toaster_note_created:"Notiz eingefügt",
    toaster_note_saved:"Notiz erfolgreich gespeichert.",
    toaster_note_deleted:"Notiz gelöscht",

    // New translations
    request_dates_finalized: 'Request dates finalized',
    has_been_added_by: 'has been added by',
    has_been_editet_tetris_ej: ' has been edited in Tetris and EasyJob by ',
    has_been_editet_tetris: ' has been edited in Tetris by ',
    has_been_changed_in_tetris: ' has changed in Tetris by ',
    please_reload_page_request: 'Please reload the page in order to see the changes!',
    new_note_added_by: 'A new note has been added by',
    note_deleted_by: 'A note has been deleted by',
    note_edited_by: 'A note has been edited by', 
    confirm_increase_material_amount_ej: 'This operation will increase the amount of the material also in Easyjob',
    mat_amount_increased_by_one: 'This materials amount was increased by one!',
};

export default de;
