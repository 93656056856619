<template>
  <div id="app" class="m-0">
    <!-- Login -->
    <transition name="fade" v-if="!user && login_was_checked" mode="out-in">
      <login></login>
    </transition>
    <transition v-else name="slide-fade" mode="out-in">
      <div>
        <div
          class="notification is-primary save-job-fixed-notify"
          v-if="general_toaster != ''"
        >
          <button class="delete" @click.prevent="general_toaster = ''"></button>
          {{ general_toaster }}
        </div>
        <!-- Navbar -->
        <navbar
          v-if="$route.path.split('/')[1] != 'loading-module'"
          class=""
        ></navbar>
        <!-- VIEW / EACH STEP -->
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </transition>
    <materials-notes-modal v-if="show_notes_modal"></materials-notes-modal>
    <help v-if="user" :app_lang="app_lang" :app_route="app_route"></help>
    <!-- note modal :material_id =  -->
    <!-- {{materials_list}} -->
  </div>
</template>

<script>
// import {EventBus} from '@/event-bus'
import EApi from "@/services/EApi";
import qs from "qs";
import axios from "axios";
import { EventBus } from "@/event-bus";
var CryptoJS = require("crypto-js");
// import moment from 'moment'

export default {
  name: "App",
  data() {
    return {
      currentEvent: 0, //localStorage.currentEvent ? localStorage.currentEvent : 0,

      events: [],

      event_details: {}, //localStorage.event_details ? JSON.parse(localStorage.event_details) : {},

      requestedWarehouses: [], //localStorage.requestedWarehouses ? JSON.parse(localStorage.requestedWarehouses) : [],

      warehouses: [],

      requestedWarehouseMaterials: [],

      materials: [],

      materials_list: [],

      materials_data: [],

      jobs: [],

      event_jobs: [],

      currentJobItems: [],

      current_job_open: false,

      all_materials: [],

      project_bill_of_items: [],

      user: null,
      login_was_checked: false,

      materialsAvailability: [],

      masterJobMaterials: [],

      truck_prefix: "",

      tetris_project_materials_requested: [],
      ej_project_materials_requested: [],

      show_notes_modal: false,

      current_projects_jobs_material: [],

      general_toaster: "",

      loaded_job_requests: [],

      requested_job_requests: [],

      selected_truck_job: {},

      app_lang: "",

      app_route: "",

      project_parameters_count: 0,

      request_jobs_details: [],

      working_version: 1,
      project_note: "",
      notes: [],
      note_priority: 0,
    };
  },
  components: {
    navbar: () => import("@/layout/navbar"),
    login: () => import("@/pages/login"),
    "materials-notes-modal": () =>
      import("@/components/modals/materials-notes-modal.vue"),
    help: () => import("@/components/modals/help.vue"),
  },

  watch: {
    requestedWarehouses: function (v) {
      localStorage.requestedWarehouses = JSON.stringify(v);
    },

    event_details: function (v) {
      localStorage.event_details = JSON.stringify(v);
    },

    user: function (v) {
      localStorage.user = JSON.stringify(v);
    },
  },

  computed: {
    currentRoute() {
      return this.$route.path;
    },

    event() {
      let event = { name: "", organizer: "" };
      this.events.forEach((element) => {
        if (element.Id == this.currentEvent) event = element;
      });
      return event;
    },

    jobs_requests() {
      let l = this.jobs.filter((j) => {
        return j.Caption.toLowerCase().indexOf("- request") > -1;
      });
      return l; /*.map(j => {
				j.materials.map(m => {
					m.date_amount.map(md => {
						md.dates = [new Date(md.dates[0]), new Date(md.dates[1])]
						return md
					})
					return m
				})
				return j
			})*/
    },

    jobs_warehouses() {
      return this.event_jobs.filter((j) => {
        return j.Caption.toLowerCase().includes("- w:");
      });
    },

    are_jobs_loaded() {
      // console.log("are_jobs_loaded", this.loaded_job_requests);
      let a = [...this.loaded_job_requests].sort();
      let b = [...this.requested_job_requests].sort();
      let c = JSON.stringify(a) >= JSON.stringify(b);
      // console.log(this.requested_job_requests, ' job req')
      return c;
    },
  },

  methods: {
    getWarehouseJobDetails() {
      // Get projects_warehouse_jobs (departure - pickup date)
      axios
        .post(this.$tetris_server + "/all/project/warehouse/jobs", {
          project_id: this.currentEvent,
        })
        .then((r) => {
          this.event_jobs = [
            ...this.event_jobs.map((j) => {
              r.data.map((dd) => {
                if (j.IdJob == dd.ej_id) {
                  j = {
                    ...j,
                    details: {
                      ...j.details,
                      departure_date: dd.departure_date,
                      pickup_date: dd.pickup_date,
                    },
                  };
                }
              });
              return j;
            }),
          ];
        });
    },

    setTetrisRequestArray(rj) {
      this.request_jobs_details = rj;
    },

    setRequestJobTetrisStatus(rj) {
      if (rj.length == 1) {
        this.jobs = [
          ...this.jobs.map((j) => {
            if (j.Caption.toLowerCase().indexOf("- request") > -1)
              if (j.IdJob == rj[0].ej_id) {
                j.RequestJobStatus = rj[0].status_id;
              }
            return j;
          }),
        ];
      }
    },

    async getEJData(e) {
      return await axios.get(this.$ej_server + "/api.json/" + e, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
    },

    async getEJDatas(e) {
      return await axios.get(this.$ej_server + "/api.json/" + e, {
        headers: this.$ej_headers,
      });
    },

    async postEJDatas(e, p) {
      return await axios.post(this.$ej_server + "/api.json/" + e, p, {
        headers: this.$ej_headers,
      });
    },

    getProjects(search, mode = "all") {
      this.events = [];
      if (mode == "all")
        this.getEJData("projects/list?searchtext=" + search + "%").then((r) => {
          if (r.status == 200) this.events = r.data;
        });
    },

    async getProject() {
      let list_of_linked_materials = [];
      if (this.currentEvent)
        await this.getEJData("Projects/Details/" + this.currentEvent).then(
          async (r) => {
            if (r.status == 200) {
              this.event_details = r.data;
              this.requestedWarehouses = [];
              // set page title
              this.$document.title = this.event_details.Caption;
              // must check if has more than one ??
              this.requestedWarehouses.push(this.event_details.Stock);
              localStorage.requestedWarehouses = this.requestedWarehouses;
              await this.getEJData(
                "custom/projectbillofitems/" + this.currentEvent
              ).then(async (res) => {
                this.project_bill_of_items = res.data;
                this.materials = [];
                this.jobs = [];
                // get all project jobs
                this.event_jobs = this.event_details.Jobs;
                this.requested_job_requests = [];
                this.loaded_job_requests = [];

                this.project_bill_of_items.Jobs.map((j) => {
                  j.Items.map((m) => {
                    if (!list_of_linked_materials.includes(m.IdST))
                      list_of_linked_materials.push(m.IdST);
                  });
                });

                // get request job and warehouse jobs items
                for (const event_job of this.event_details.Jobs) {
                  // exclude the truck jobs so the checkouts and checkins
                  if (
                    !event_job.Caption.toLowerCase().includes("- t:") &&
                    !event_job.Caption.toLowerCase().includes("- wt:")
                  ) {
                    await this.getJobItems(event_job.IdJob);

                    // this is used to determinate the counting of requested jobs that were already loaded jobs from the requests
                    this.requested_job_requests.push(event_job.IdJob);
                  }
                }

                // get project input form data
                this.getProjectFormData();
              });

              await axios
                .post(this.$tetris_server + "/stock/data", {
                  list: [],
                  linked: list_of_linked_materials,
                })
                .then((response) => {
                  this.materials_data = response.data;
                  this.getWarehouseJobDetails();
                  this.setJobMaterialsExtraInfo();
                });

              this.get_truck_prefix();
            }
          }
        );
    },

    getLinkedMaterial(idst, full = false) {
      let va = 0;
      let fu = {};
      this.materials_data.map((m) => {
        if (m.IdST == idst && m.linked) {
          if (!full) va = m.linked.material_stocktype;
          else fu = m.linked;
        }
      });
      if (!full) return va;
      else return fu;
    },

    // get the truck prefix that was specified for the project
    get_truck_prefix() {
      console.log("getting truck prefix");
      axios
        .post(this.$tetris_server + "/get/project/data", {
          project_id: parseInt(this.currentEvent),
        })
        .then((d) => {
          if (d.data.length > 0) {
            this.truck_prefix = d.data[0].truck_prefix;
            this.working_version = d.data[0].version;
          } else this.truck_prefix = "";
        });
    },

    // Get the easyjob list of warehouses
    getProjectFormData() {
      this.getEJData("Projects/GetFormData/" + this.currentEvent).then((r) => {
        if (r.status == 200) {
          this.warehouses = r.data.StockSelection;
          localStorage.warehouses = JSON.stringify(this.warehouses);
        }
      });
    },

    // gets a job with details and items this is used by the modal that opens a job in easyjob at the warehouse step
    openJob(job) {
      this.current_job = job;
      this.getEJData("Jobs/Details/" + job.IdJob).then((jd) => {
        this.current_job = jd.data;
        this.getEJData("Items/BillOfItems/" + job.IdJob).then((r) => {
          if (r.status == 200) {
            this.currentJobItems = r.data;
            this.current_job_open = true;
          }
        });
      });
    },

    // The main request that gives all the data of a project
    // gets data about the jobs of the project
    async getJobItems(job) {
      //get job details and items
      this.getEJData("Jobs/Details/" + job).then(async (jd) => {
        this.event_jobs = [
          ...this.event_jobs.map((j) => {
            if (j.ID == job) j.details = jd.data;
            return j;
          }),
        ];

        jd.data.dates = [
          jd.data.DayTimeOut.split("T")[0],
          jd.data.DayTimeIn.split("T")[0],
        ];

        const path_name = this.$route.path.slice(
          this.$route.path.indexOf("/") + 1,
          this.$route.path.lastIndexOf("/")
        );

        // IMPORTANT NOTE: Get the address of delivery from the request job and if it exists use it for the project address
        // of delivery.. this is used in the planning and in the exports
        if (jd.data.Caption.toLowerCase().includes("- request")) {
          if (jd.data.Address_Delivery)
            this.event_details.Address_Customer = {
              ...jd.data.Address_Delivery,
              Company: this.event_details.Address_Customer.Company,
              CompanyName: jd.data.Address_Delivery.Company,
            };
          this.event_details.StartDate = jd.data.DayTimeOut.split("T")[0];
          this.event_details.EndDate = jd.data.DayTimeIn.split("T")[0];
        }

        // Get here the materials of the request job in tetris...
        // do this only if the job is the request job
        if (
          !jd.data.Caption.toLowerCase().includes(" - w:") && // exclude warehouse jobs
          !jd.data.Caption.toLowerCase().includes(" - t:") && // exclude truck jobs
          !jd.data.Caption.toLowerCase().includes(" - wt:") && // exclude bullshit DEPRECATED
          !jd.data.Caption.toLowerCase().includes("transfer")
        ) {
          console.log(jd.data.Caption, "caption");
          // if (
          //   jd.data.JobState.IdJobState == 1 &&
          //   path_name == "delivery-request"
          // ) {
          //   alert(
          //     "Project cannot be confirmed! " + jd.data.JobState.IdJobState
          //   );
          // } else

          console.log(path_name);
          await axios
            .post(this.$tetris_server + "/get/job/materials", {
              job_id: job,
              project_id: this.currentEvent,
              dates: jd.data.dates,
              number: this.event_details.Number,
              altdates: [jd.data.DayTimeOut, jd.data.DayTimeIn],
            })
            .then((resp) => {
              // if there are some materials of this job synced in the tetris
              // should continue
              if (resp.data.length > 0) {
                console.log(resp.data);
                this.tetris_project_materials_requested = resp.data;

                this.project_bill_of_items.Jobs.map((jbl) => {
                  var materials = [];
                  if (jbl.IdJob == job) {
                    let r = { data: jbl.Items };
                    this.ej_project_materials_requested = [...r.data];
                    materials = r.data.map((m) => {
                      let found = false;
                      // set tetris dates to the materials of the easyjob request if fount
                      resp.data.forEach((material) => {
                        if (material.material_id == m.IdST) {
                          found = true;
                          var dt1 = new Date(material.start_date.split(" ")[0]);
                          var dt2 = new Date(material.end_date.split(" ")[0]);
                          // dt1 = new Date(dt1.getTime() - dt1.getTimezoneOffset() * 2 * 60 * 1000) // Date(material.start_date)
                          // dt2 = new Date(dt2.getTime() - dt2.getTimezoneOffset() * 2 * 60 * 1000) // Date(material.start_date)
                          // let linked_to = 0; //this.getLinkedMaterial(material.material_id)
                          let v = {
                            dates: [dt1, dt2],
                            dates_original: [
                              material.start_date,
                              material.end_date,
                            ],
                            date_was: [dt1, dt2], // just to track changes
                            Qty: material.amount,
                            QtyWas: material.amount, // just to track changes
                            added: material.warehouse_job
                              ? 2
                              : material.connected_project_id
                              ? 3
                              : 0, // set the material to already created a job for it -> 2 or not set any job -> 0
                            // here could be used some localstorage instead of default 0
                            warehouse_job: material.warehouse_job, // this is the warehouse to which the job was assigned
                            m_id: material.m_id,
                            m_guid: material.m_guid,
                            m_connected_project_id:
                              material.connected_project_id,
                            m_connected_project: material.connected_project,
                            m_connected_project_name:
                              material.connected_project_name,
                            notes_all: material.notes_all,
                            m_linked: material.linked_material_id,
                            created_by_user: material.created_by_user,
                            created_by: material.created_by,
                            request_notes: material.request_notes, // notes for materials in the different steps
                            warehouse_notes: material.warehouse_notes, // ...
                            planning_notes: material.planning_notes, // ...
                          };
                          // console.log('aadding ', m.IdST, material.linked_material_id, material)
                          // create key for storin the dates
                          if (!m.date_amount) m.date_amount = [];

                          m.date_amount.push({ ...v });
                        }
                      });

                      // if the material was not synced this will create dummy date to add in the list
                      if (!found) {
                        console.log("not found");
                        m.dates = jd.data.dates;
                        // m.date_was = jd.data.dates
                        m.date_was = "1970-01-01";
                        // m.QtyWas = m.Qty
                        m.QtyWas = m.Qty;
                        return { ...m, date_amount: [{ ...m }] };
                      } else return { ...m };
                    });
                    jd.data.materials = [...materials];
                  }
                });
                jd.data.expanded = true;

                // this.ej_project_materials_requested = jd.data.materials

                this.jobs.push(jd.data);
                this.jobs = this.jobs.sort(this.sortIds);

                // this.setJobMaterialsExtraInfo()

                // localStorage.jobs = JSON.stringify(this.jobs)
              }
              // if there are no material at all about this project in tetris do the initial save/sync of all the items of the request job
              else {
                // create list
                this.project_bill_of_items.Jobs.map((jbl) => {
                  if (jbl.IdJob == job) {
                    let r = { data: jbl.Items };
                    jd.data.materials = r.data.map((m) => {
                      // fake structure
                      m.dates = jd.data.dates;
                      m.QtyWas = m.Qty;
                      return { ...m, date_amount: [{ ...m }] };
                    });
                  }
                });

                if (jd.data.materials[0].IdST !== null) {
                  //if there are  materials for the job from easyjob
                  console.log(jd.data.materials, "data materials");
                  var materials_sync = [];
                  jd.data.materials.map((mat) => {
                    var post_data = {
                      IdJob: jd.data.ID,
                      IdStockType: mat.IdST,
                      IdStockType2JobGroup: mat.IdST2JG,
                      IdStockType2JobType: mat.IdST2JT,
                      Quantity: 0,
                      list: mat.date_amount.map((mm) => {
                        return {
                          Qty: mm.Qty,
                          dates: mm.dates,
                          m_guid: mm.m_guid,
                        };
                      }),
                    };
                    materials_sync.push(post_data);
                  });

                  axios
                    .post(this.$tetris_server + "/add/job/materials", {
                      materials: materials_sync,
                      project: this.event_details,
                      job: { ID: jd.data.ID, Caption: jd.data.Caption },
                    })
                    .then(() => {
                      alert("Project synced");
                      window.location.reload();
                    });
                } else {
                  // debugger; // eslint-disable-line no-debugger

                  alert("This Project has no materials. Add them to sync! ");
                }
                // var prmslist = [];
                // var delay = 1;

                jd.data.expanded = true;

                this.jobs.push(jd.data);

                // here should sort the dates
                this.jobs = [...this.jobs.sort(this.sortIds)];
                // this.setJobMaterialsExtraInfo()
              }

              setTimeout(() => {
                this.loaded_job_requests.push(job);

                // set localstorage of all jobs
                localStorage.jobs = JSON.stringify(this.jobs);
                // EXTEND MATERIALS INFO from the main list of the material (stock/data)... adding more details to the job materials
                // like number, weight, height etc fields that are not given by the easyjob api calls
                // this.setJobMaterialsExtraInfo()

                setTimeout(() => {
                  if (this.request_jobs_details.length > 0) {
                    this.setRequestJobTetrisStatus(this.request_jobs_details);
                  }
                }, 0);
              }, 0);
            });
          // set the materials of the jobs of the warehouses
        } else if (jd.data.Caption.toLowerCase().includes(" - w:")) {
          this.project_bill_of_items.Jobs.map((jbl) => {
            if (jbl.IdJob == job) {
              let r = { data: jbl.Items };
              jd.data.materials = r.data.map((m) => {
                m.dates = jd.data.dates;
                m.QtyWas = m.Qty;
                return { ...m, date_amount: [{ ...m }] };
              });
            }
          });
          jd.data.expanded = true;

          this.jobs.push(jd.data);
          this.jobs = this.jobs.sort(this.sortIds);

          // set localstorage of all jobs
          localStorage.jobs = JSON.stringify(this.jobs);
          // this.setJobMaterialsExtraInfo()

          setTimeout(() => {
            this.loaded_job_requests.push(job);
          }, 0);
        }
      });
    },

    setJobMaterialsExtraInfo() {
      setTimeout(() => {
        // console.log(this.jobs, "materials data stock")
        this.jobs = [
          ...this.jobs.map((e) => {
            e.materials = [
              ...e.materials.map((mj) => {
                mj.date_amount = [
                  ...mj.date_amount.map((da) => {
                    if (da.m_id) {
                      da.m_linked = this.getLinkedMaterial(mj.IdST, false);
                    }
                    return da;
                  }),
                ];
                mj.linked = this.getLinkedMaterial(mj.IdST, true);
                this.materials_data.map((m) => {
                  if (m.IdStockType == mj.IdST) {
                    mj.Number = parseInt(m.Number);
                    mj.linked = m.linked;

                    if (mj.linked) {
                      mj.QtyLinked = Math.ceil(
                        parseFloat(mj.Qty) / parseFloat(mj.linked.factor)
                      );
                    }

                    //... here add more fields
                  }
                  return m;
                });

                if (e.Caption.toLowerCase().indexOf("- request") == -1) {
                  mj.LinkedMaterialNotes = this.getLinkedMaterialNotes(mj, e);
                }
                return mj;
              }),
            ];
            return e;
          }),
        ];
      }, 0);
    },

    getLinkedMaterialNotes(m, jd) {
      let a = 0;
      this.jobs.map((j) => {
        j.materials.map((mat) => {
          // if(mat.date_amount)
          mat.date_amount.map((md) => {
            if (md.m_linked == m.IdST && md.warehouse_job == jd.IdJob) {
              a = parseInt(md.notes_all ? md.notes_all : 0);
              // console.log(j)
              // console.lj.Caption,aption,
              // console.log(md.m_guid, m.IdST, md, m.job_id)
            }
          });
          //console.log(m)
          // if(m.IdST == mat)
        });
      });

      if (a > 0) return a;
    },

    sortDates(a, b) {
      let d1 = new Date(a.DayTimeOut);
      let d2 = new Date(b.DayTimeOut);
      // var da = new Date( d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds() );
      // var db = new Date( d2.getUTCFullYear(), d2.getUTCMonth(), d2.getUTCDate(), d2.getUTCHours(), d2.getUTCMinutes(), d2.getUTCSeconds() );
      return d1 - d2;
    },

    sortIds(a, b) {
      return a.IdJob - b.IdJob;
    },

    getJobWarehouse(j, d = null, m = null) {
      var jjj;
      this.jobs.forEach((job) => {
        if (job.IdJob == j) jjj = job.Stock.Caption;
      });
      if (!jjj) {
        // pending job here
        if (m == null)
          // console.log('.')
          this.masterJobMaterials.forEach((m) => {
            m.dates.forEach((date) => {
              if (date.hash == d.hash) jjj = m.warehouse.Caption;
            });
          });
      }
      return jjj;
    },

    getWebSettings() {
      // get current user data
      this.getEJDatas("common/getwebsettings")
        .then((r) => {
          this.user = r.data;

          axios
            .post(this.$tetris_server + "/user/heartbeat", this.user)
            .then(() => {});

          // 1. Get all Projects DISABLED TO ALLOW MULTIPLE PROJECTS
          // if(this.currentEvent == 0)
          //     this.$router.push('/')
          // else {
          //     this.getProject()
          // }
        })
        .catch(() => {
          localStorage.clear();
          window.location.reload();
        });
    },

    getItems() {
      // get items
      // this.getEJData("Items/List/").then((r) => {
      //   this.all_materials = r.data;
      // });
    },

    getItemAvailability(item, m, w) {
      // get item availability
      this.getEJData(
        "Items/Avail/" +
          item.IdST +
          "?startdate=" +
          this.$options.filters.systemdate(m.dates[0]) +
          "&enddate=" +
          this.$options.filters.systemdate(m.dates[1]) +
          "&stock=" +
          w
      ).then((r) => {
        this.materialsAvailability = r.data;
      });
    },

    doLogin(user) {
      let ss = {
        grant_type: "password",
        username: user.username,
        password: user.password,
      };
      let d = qs.stringify(ss);

      // get token if not available
      // if(!localStorage.access_token)
      EApi(false)
        .post("/token", d)
        .then((r) => {
          localStorage.access_token = r.data.access_token;
          axios.post(this.$tetris_server + "/user/auth", r.data).then((res) => {
            localStorage.last_login = res.data[0].last_login;
            localStorage.tetris_user = JSON.stringify(res.data[0]);
            localStorage.token = res.data[1];
            var a = CryptoJS.AES.encrypt(
              JSON.stringify(res.data[2]),
              localStorage.token
            ).toString();
            localStorage.permissions = a;
            this.login_was_checked = true;
            window.location.reload();
          });
          // this.getWebSettings()
        })
        .catch(() => {
          // console.log(e)
          alert("There is something wrong with your login credentials!");
          this.login_was_checked = true;
        });
    },

    doLogout() {
      this.$fire({
        // title: 'Are you sure?',
        text: `Are you sure you want to logout?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        width: 300,
        customClass: {
          confirmButton: "button is-success is-small",
          cancelButton: "button is-small",
        },
      }).then((response) => {
        if (response.value) {
          localStorage.clear();
          window.location.reload();
        }
      });
    },

    open_material_notes(material, step_name, ej_material_id, job_id) {
      this.current_projects_jobs_material = {
        ...material,
        m_guid: material.guid ? material.guid : material.m_guid,
        step_name: step_name ? step_name : null,
        ej_material_id: ej_material_id ? ej_material_id : null,
        job_id: job_id ? job_id : null,
        material_name: material.Caption ? material.Caption : "",
      };
      this.show_notes_modal = true;
    },

    get_url() {
      var url = this.$route.path.split("/");
      var route = "";
      if (url[1] == "") {
        route = "/";
      } else {
        route = url[1];
      }
      return route;
    },

    get_project_parameters_count() {
      // axios
      //   .post(this.$tetris_server + "/get/project/parameters/count", {
      //     project_id: p_id,
      //   })
      //   .then((r) => {
      //     this.project_parameters_count = r.data[0].count;
      //     // return JSON.parse(r.data[0])
      //   });
    },

    createProjectNote() {
      if (this.project_note) {
        axios
          .post(this.$tetris_server + "/create/project/notes", {
            user: JSON.parse(localStorage.user),
            project_id: this.currentEvent,
            note: this.project_note,
            priority: this.note_priority,
          })
          .then((res) => {
            if (res.data == "note_empty_toaster") {
              this.$toast("Please write a note before submitting", "danger");
            } else {
              this.notes.unshift(res.data[0]);
              this.$toast("Note created", "success");
              this.project_note = "";
            }
          });
      }
    },

    getProjectNotes() {
      if (this.currentEvent)
        axios
          .post(this.$tetris_server + "/get/project/notes", {
            project_id: this.currentEvent,
          })
          .then((res) => {
            this.notes = [...res.data];
          });
    },

    delete_note(guid) {
      this.$fire({
        text: this.$t("sure_delete_note"),
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        width: 300,
        customClass: {
          confirmButton: "button is-success is-small",
          cancelButton: "button is-small",
        },
      }).then((response) => {
        if (response.value) {
          axios
            .post(this.$tetris_server + "/delete/project/note", { guid: guid })
            .then(() => {
              this.notes = [...this.notes.filter((n) => n.guid != guid)];
              this.$toast("Note deleted", "danger");
            });
        }
      });
    },
  },
  created() {
    if (localStorage.access_token) this.getWebSettings();
    else this.login_was_checked = true;

    // this intercepts all http calls made by the client to tetris server
    axios.interceptors.request.use(
      function (config) {
        //console.log(config)
        const token = localStorage.token;
        if (token && !config.url.includes("eps.mio-cloud.com")) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      function (err) {
        return Promise.reject(err);
      }
    );
    // Set app_route
    EventBus.$on("set_route", (data) => {
      this.app_route = data;
    });

    EventBus.$on("set_lang", (data) => {
      this.app_lang = data;
    });
  },
  destroyed: function () {
    EventBus.$off("set_route");
    EventBus.$off("set_lang");
  },
};
</script>

<style>
.icenter {
  margin: 0 auto !important;
}

.step {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  background: #ffffff;
  color: #888;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px rgb(145 145 145 / 45%);
}

.router-link-exact-active {
  font-weight: bold;
  color: #7ebcfc !important;
}

.router-link-exact-active .step {
  /* background: #e5e9ff; */
  /* background: #54a9af; */
  color: #fff;
  background: #3a5369;
  border-color: #346a6e;
}

.no-line-height {
  line-height: 1;
}

.thin-select {
  background: none !important;
  border: none;
}

.is-cobalt-text-1 {
  color: #44bde6 !important;
}

.is-cobalt-text-2 {
  color: #77bfe1 !important;
}

.is-cobalt-text-3 {
  color: #0c3b4b !important;
}

.is-cobalt-text-4 {
  color: #047698 !important;
}

.is-cobalt-text-5 {
  color: #043c44 !important;
}

.is-cobalt-text-6 {
  color: #4c6f80 !important;
}

.is-cobalt-1 {
  background: #44bde6 !important;
}

.is-cobalt-2 {
  background: #74b4c4 !important;
}

.is-cobalt-3 {
  background: #0c3b4b !important;
}

.is-cobalt-4 {
  background: #047698 !important;
}

.is-cobalt-5 {
  background: #043c44 !important;
}

.is-cobalt-6 {
  background: #4c6f80 !important;
}

.has-text-green-light {
  color: #b3d78d;
}

/* Dashboard temp styles */
/* Background only */
.is-background-palete-orange-3 {
  /* background: #f7d67e!important; */
  background: #eccd94 !important;
}

.is-background-palete-blue-3 {
  /* background: #f7d67e!important; */
  background: #e7efff !important;
}

/* Background and text */
.is-color-palete-green-1 {
  background: #8bb522;
  color: #fff !important;
}

.is-color-palete-green-2 {
  background: #56bca4 !important;
  color: #fff !important;
}

.is-color-palete-orange-1 {
  background: #ddb03b;
  color: #fff !important;
}

.is-color-palete-orange-2 {
  background: #e37032;
  color: #fff !important;
}

.is-color-palete-blue-1 {
  background: #2494a2 !important;
  color: #fff !important;
}

.is-color-palete-blue-2 {
  background: #9ad0e1 !important;
}

.is-color-palete-blue-3 {
  background: #ddf1f6 !important;
}

.is-color-palete-blue-4 {
  background: #f4fdff !important;
}

.is-color-palete-yellow-1 {
  /* background: #fefde3!important; */
  background: #fffddc !important;
  /*  #eceac3!important; */
}

.is-color-palete-yellow-2 {
  background: #eccd94 !important;
}

/* Text */
.is-text-palete-green-1 {
  color: #8bb522;
}

.is-text-palete-green-2 {
  color: #56bca4;
}

.is-text-palete-green-3 {
  color: #1f8958 !important;
}

.is-text-palete-orange-1 {
  color: #ddb03b;
}

.is-text-palete-orange-2 {
  color: #e37032;
}

.is-text-palete-orange-3 {
  color: #ad9148;
}

.is-text-palete-orange-4 {
  color: #bb1c1c;
}

bb1c1c .is-text-palete-blue-1 {
  color: #2494a2;
}

.is-text-palete-blue-2 {
  color: #9ad0e1;
}

.is-text-palete-blue-3 {
  color: #ddf1f6;
}

.is-text-palete-blue-4 {
  color: #1900ff;
}

/* Border bottoms */
.has-border-bottom-green-1 {
  border-bottom: solid 1px #8bb522;
}

.has-border-bottom-green-2 {
  border-bottom: solid 1px #56bca4;
}

.has-border-bottom-orange-1 {
  border-bottom: solid 1px #ddb03b;
}

.has-border-bottom-orange-2 {
  border-bottom: solid 1px #e37032;
}

.has-border-bottom-blue-1 {
  border-bottom: solid 1px #2494a2;
}

.has-border-bottom-blue-2 {
  border-bottom: solid 1px #9ad0e1;
}

/* Table heading border */
.th-border-green-1 th {
  border-bottom-color: #8bb522 !important;
}

.th-border-bottom-green-2 th {
  border-bottom-color: #56bca4;
}

.th-border-orange-1 th {
  border-bottom-color: #ddb03b !important;
}

.th-border-bottom-orange-2 th {
  border-bottom-color: #e37032;
}

.th-border-blue-1 th {
  border-bottom-color: #2494a2 !important;
}

.th-border-bottom-blue-2 th {
  border-bottom-color: #9ad0e1;
}

/* Border color */

.has-border-green-1 {
  border-color: solid 1px #8bb522;
}

.has-border-green-2 {
  border-color: solid 1px #56bca4;
}

.has-border-green-3 {
  border-color: solid 1px #1f8958;
}

.has-border-orange-1 {
  border-color: solid 1px #ddb03b;
}

.has-border-orange-2 {
  border-color: solid 1px #e37032;
}

.has-border-blue-1 {
  border-color: solid 1px #2494a2;
}

.has-border-blue-2 {
  border-color: solid 1px #9ad0e1;
}

.flatpickr-confirm {
  background: #1f2d3b !important;
  color: #fff !important;
}

.fc-view-harness {
  background: #fffddc !important;
}

.fc-col-header-cell-cushion {
  color: #000 !important;
}
</style>
