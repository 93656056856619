import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import de from './dashboard/de';
import en from './dashboard/en';

import general_de from './general/de';
import general_en from './general/en';


import navbar_de from './navbar/de';
import navbar_en from './navbar/en';

import request_de from './request/de';
import request_en from './request/en';

import warehouse_de from './warehouse/de';
import warehouse_en from './warehouse/en';

import parameters_de from './parameters/de';
import parameters_en from './parameters/en';

import planning_de from './planning/de';
import planning_en from './planning/en';

import user_role_de from './user_role/de';
import user_role_en from './user_role/en';

import chat_de from './chat-component/de';
import chat_en from './chat-component/en';

var EN = {
    ...en, 
    ...general_en,
    ...navbar_en,
    ...request_en,
    ...warehouse_en,
    ...parameters_en,
    ...planning_en,
    ...user_role_en,
    ...chat_en
};

var DE = {
    ...de, 
    ...general_de,
    ...navbar_de,
    ...request_de,
    ...warehouse_de,
    ...parameters_de, 
    ...planning_de,
    ...user_role_de,
    ...chat_de
};

const messages = {
    'en': EN,
    'de': DE
}

const i18n = new VueI18n ({
    locale : 'en',
    fallbackLocale : 'en',
    messages
});

export default i18n;